body {
  height: 100%;
  background-color: #e9e9e9;

  .header,
  .footer {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
  }
}
