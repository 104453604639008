// COLORS
$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;
$black: #000;
$white: #fff;
$gray: #6C6C6C;

// BREAKPOINTS
$screen-xs: 400px;
$screen-sm: 575px;
$screen-md: 767px;
$screen-lg: 991px;
$screen-xl: 1199px;

// FONT FAMILY
$font-regular: sans-serif;
$font-medium: sans-serif;
$font-bold: sans-serif;

// BOX SHADOW
$box-shadow-default: 0 0 10px 2px rgba(0, 0, 0, 0.1);
